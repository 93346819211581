import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppBar from './app-bar';
import './leaderboard.css';
import { getTranslation } from './translations';

function InviteFriends() {
  const [friends, setFriends] = useState([]);
  const [inviteLink, setInviteLink] = useState('');
  const [totalEarningsFromReferrals, setTotalEarningsFromReferrals] = useState(0);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://fast.digitaldollscrypto.art';
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.language_code) {
        const language = telegramUser.language_code.toLowerCase().startsWith('ru') ? 'ru' : 'en';
        setCurrentLanguage(language);
      }
    }
  }, []);

  const fetchInviteLink = async () => {
    if (!telegramUser?.id) {
      console.error('Telegram user ID not found');
      toast.error(getTranslation(currentLanguage, 'telegramUserIdNotFound'));
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/generate_invite_link`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telegramId: telegramUser.id })
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setInviteLink(data.inviteLink);
      } else {
        console.error('Failed to generate invite link:', data.error);
        toast.error(getTranslation(currentLanguage, 'failedToGenerateInviteLink'));
      }
    } catch (err) {
      console.error('Fetch error:', err);
      toast.error(getTranslation(currentLanguage, 'fetchError'));
    }
  };

  const copyInviteLink = () => {
    if (inviteLink) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(inviteLink)
          .then(() => toast.success(getTranslation(currentLanguage, 'inviteLinkCopied')))
          .catch(err => {
            console.error('Failed to copy: ', err);
            fallbackCopyTextToClipboard(inviteLink);
          });
      } else {
        fallbackCopyTextToClipboard(inviteLink);
      }
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        toast.success(getTranslation(currentLanguage, 'inviteLinkCopied'));
      } else {
        toast.error(getTranslation(currentLanguage, 'unableToCopyInviteLink'));
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      toast.error(getTranslation(currentLanguage, 'unableToCopyInviteLink'));
    }

    document.body.removeChild(textArea);
  };

  const shareInviteLink = () => {
    if (inviteLink) {
      if (window.Telegram && window.Telegram.WebApp.openTelegramLink) {
        window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(inviteLink)}`);
      } else {
        toast.info(getTranslation(currentLanguage, 'copyLinkManually'));
      }
    }
  };

  const fetchInvitedFriends = async () => {
    if (!telegramUser?.id) {
      console.error('Telegram user ID not found');
      toast.error(getTranslation(currentLanguage, 'telegramUserIdNotFound'));
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/invited_friends`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telegramId: telegramUser.id })
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setFriends(data.friends);
        setTotalEarningsFromReferrals(data.totalEarnings);
      } else {
        console.error('Failed to fetch invited friends:', data.error);
        toast.error(getTranslation(currentLanguage, 'failedToFetchInvitedFriends'));
      }
    } catch (err) {
      console.error('Fetch error:', err);
      toast.error(getTranslation(currentLanguage, 'fetchError'));
    }
  };

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate(-1);
      });
    }
  
    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, [navigate]);

  useEffect(() => {
    fetchInviteLink();
    fetchInvitedFriends();
  }, []);

  const formatBalance = (balance) => {
    if (balance === undefined || balance === null || isNaN(balance)) {
      return '0';
    }
    if (balance >= 1000000) {
      return `${(balance / 1000000).toFixed(1)}M`;
    } else if (balance >= 1000) {
      return `${(balance / 1000).toFixed(1)}K`;
    }
    return balance.toString();
  };

  return (
    <div className="font-rubik" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        toastStyle={{
          background: "#000",
          color: "#fff"
        }}
        progressStyle={{
          background: "#8A2BE2"
        }}
      />
      <div className="_container_1xx37_1" style={{ flex: 1, overflowY: 'auto' }}>
      <div className="_refScreen_4qfe4_1 scrollable" style={{ paddingBottom: '120px' }}>
          <div>
            <div className="_refScreen__titleBlock_4qfe4_10">
              <span className="_refScreen__titleBlock__invite_4qfe4_18">{getTranslation(currentLanguage, 'referral')}</span>
              <h1 className="_refScreen__titleBlock__title_4qfe4_26">{getTranslation(currentLanguage, 'inviteFriends')}</h1>
            </div>
            <div className="_refScreen__rewardInfo_4qfe4_32">
              <div className="_borderGradient_4qfe4_133">
                <div className="_refScreen__rewardBlock_4qfe4_39">
                  <p className="_refScreen__rewardBlock__title_4qfe4_48">{getTranslation(currentLanguage, 'inviteFriends')}</p>
                  <div className="_refScreen__rewardBlock__reward_4qfe4_54">
                    <img className="coin img-responsive is-20" src="/images/icons/coin.png" alt="Coin" />
                    <span>+2,000</span>
                  </div>
                </div>
              </div>
              <div className="_borderGradient_4qfe4_133">
                <div className="_refScreen__rewardBlock_4qfe4_39">
                  <p className="_refScreen__rewardBlock__title_4qfe4_48">{getTranslation(currentLanguage, 'earnedFromReferrals')}</p>
                  <div className="_refScreen__rewardBlock__reward_4qfe4_54">
                    <img className="coin img-responsive is-20" src="/images/icons/coin.png" alt="Coin" />
                    <span>+{formatBalance(totalEarningsFromReferrals)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="_refScreen__referralPercent_4qfe4_65 font-rubik _refScreen__referralPercent__eu_4qfe4_77">
              <p>{getTranslation(currentLanguage, 'score5PercentOfYourBuddies')}</p>
              <p></p>
            </div>
            <div>
              <div className="_borderGradient_4qfe4_133">
                <div className="_refScreen__myReferrals_4qfe4_83">
                  <div className="_refScreen__myReferrals__textBlock_4qfe4_87">
                    <p className="_refScreen__myReferrals__text_4qfe4_87 font-rubik">{getTranslation(currentLanguage, 'myReferrals')}</p>
                    <p className="_refScreen__myReferrals__counter_4qfe4_93">{friends.length}</p>
                  </div>
                  <div className="_refScreen__refList_4qfe4_129 scrollable">
                    {friends.length === 0 ? (
                      <p className="_refScreen__noReferrals_4qfe4_110">{getTranslation(currentLanguage, 'noReferralsYet')}</p>
                    ) : (
                      friends.map((friend, index) => (
                        <div key={index} className="friends-item">
                          <div className="friends-item-user">
                            <div className="user-info">
                              <div className="user-info-avatar">
                                <img src="/images/avatar.jpg" alt="Avatar" />
                              </div>
                              <div className="user-info-content">
                                <p>{friend.first_name || friend.username || friend.telegram_id}</p>
                                <div className="user-info-content-data">
                                  <span>{friend.level} </span>
                                  <span className="is-circle"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="friends-item-stats">
                            <div className="price">
                              <div className="price-image">
                                <img className="coin img-responsive is-20" src="/images/icons/coin.png" alt="Coin" />
                              </div>
                              <div className="price-value">+{formatBalance(friend.balance)}</div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div 
        className="_refScreen__buttonWrapper_4qfe4_118" 
        style={{ 
          display: 'flex', 
          gap: '10px', 
          position: 'fixed', 
          bottom: '60px',
          left: '0',
          right: '0',
          backgroundColor: '#000', 
          padding: '10px', 
          zIndex: 1 
        }}
      >
        <button 
          className="_button_fe4eh_1 _purple_fe4eh_31" 
          onClick={shareInviteLink} 
          style={{ flex: 1 }}
        >
          <span>{getTranslation(currentLanguage, 'inviteFriends')}</span>
        </button>
        <button 
          className="_button_fe4eh_1 _purple_fe4eh_31" 
          onClick={copyInviteLink} 
          style={{ 
            padding: '10px',
            width: 'auto',
            minWidth: 'unset',
            aspectRatio: '1/1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img 
            src="/images/icons/copy.svg" 
            alt="Copy" 
            style={{ 
              width: '24px', 
              height: '24px',
              filter: 'invert(1)'
            }} 
          />
        </button>
      </div>
      <AppBar language={currentLanguage} />
    </div>
  );
}

export default InviteFriends;