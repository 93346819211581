import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppBar from './app-bar';
import { useUser } from './UserContext';
import './leaderboard.css';
import './tasks.css';

const formatNumber = (num) => {
  if (num >= 1000000) return `${Math.floor(num / 1000000)}M`;
  if (num >= 1000) return `${Math.floor(num / 1000)}K`;
  return Math.floor(num).toString();
};

function GemExchange() {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [userData, setUserData] = useState({
    balance: 0,
    gem1_balance: 0,
    gem2_balance: 0,
    gem3_balance: 0,
    passive_earn: 0,
    earn_per_tap: 1,
  });
  const [selectedExchange, setSelectedExchange] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchUserData = useCallback(async () => {
    if (!user?.id) return;
  
    try {
      const tokenResponse = await axios.post(`${backendUrl}/request_token`, { telegramId: user.id });
      if (!tokenResponse.data.success) {
        throw new Error('Failed to fetch user token');
      }
  
      const userDataResponse = await axios.post(`${backendUrl}/get_user_data`, { token: tokenResponse.data.token });
      if (!userDataResponse.data.success) {
        throw new Error('Failed to fetch user data');
      }
  
      const newUserData = {
        ...userDataResponse.data.user,
        token: tokenResponse.data.token
      };
  
      setUserData(prevData => ({ ...prevData, ...newUserData }));
      
      setUser(prevUser => ({
        ...prevUser,
        ...newUserData,
        displayName: prevUser.displayName || newUserData.first_name || newUserData.username,
        first_name: prevUser.first_name || newUserData.first_name,
        username: prevUser.username || newUserData.username,
        id: prevUser.id
      }));
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to load user data. Please try again.');
    }
  }, [backendUrl, user?.id, setUser]);

  useEffect(() => {
    if (user?.id) {
      fetchUserData();
    }
  }, [user?.id, fetchUserData]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/gem-mining');
      });
    }
  
    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, [navigate]);

  const handleHapticFeedback = () => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };

  const handleGem1Exchange = async () => {
    try {
      const response = await axios.post(`${backendUrl}/exchange_gem1`, { token: userData.token });
      if (response.data.success) {
        setUserData(prevData => ({
          ...prevData,
          gem1_balance: response.data.new_gem1_balance,
          balance: response.data.new_balance
        }));
        toast.success(`Exchanged 100 Gem1 for 50,000 coins!`);
        setSelectedExchange(null);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to exchange Gem1. Please try again.');
    }
  };

  const handleGem2Exchange = async () => {
    try {
      const response = await axios.post(`${backendUrl}/exchange_gem2`, { token: userData.token });
      if (response.data.success) {
        setUserData(prevData => ({
          ...prevData,
          gem2_balance: response.data.new_gem2_balance,
          passive_earn: response.data.new_passive_earn
        }));
        toast.success(`Exchanged 100 Gem2 for +10 passive income!`);
        setSelectedExchange(null);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to exchange Gem2. Please try again.');
    }
  };

  const handleGem3Exchange = async () => {
    try {
      const response = await axios.post(`${backendUrl}/exchange_gem3`, { token: userData.token });
      if (response.data.success) {
        setUserData(prevData => ({
          ...prevData,
          gem3_balance: response.data.new_gem3_balance,
          earn_per_tap: response.data.new_earn_per_tap
        }));
        toast.success(`Exchanged 20 Gem3 for +1 earn per tap!`);
        setSelectedExchange(null);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || 'Failed to exchange Gem3. Please try again.');
    }
  };

  const exchanges = [
    {
      id: 1,
      title: "Gem1 Exchange",
      icon: "/images/gems/gem1.png",
      description: "Exchange 100 Gem1 for 50,000 coins",
      action: handleGem1Exchange,
      currentValue: userData.gem1_balance,
      requiredGems: 100,
      exchangeRate: <><img src="/images/gems/gem1.png" alt="Gem1" style={{ width: '16px', height: '16px', verticalAlign: 'middle' }} /> 100 → 50,000 coins</>
    },
    {
      id: 2,
      title: "Gem2 Exchange",
      icon: "/images/gems/gem2.png",
      description: "Exchange 100 Gem2 for +10 passive income",
      action: handleGem2Exchange,
      currentValue: userData.gem2_balance,
      requiredGems: 100,
      exchangeRate: <><img src="/images/gems/gem2.png" alt="Gem2" style={{ width: '16px', height: '16px', verticalAlign: 'middle' }} /> 100 → +10 passive income</>
    },
    {
      id: 3,
      title: "Gem3 Exchange",
      icon: "/images/gems/gem3.png",
      description: "Exchange 20 Gem3 for +1 earn per tap",
      action: handleGem3Exchange,
      currentValue: userData.gem3_balance,
      requiredGems: 20,
      exchangeRate: <><img src="/images/gems/gem3.png" alt="Gem3" style={{ width: '16px', height: '16px', verticalAlign: 'middle' }} /> 20 → +1 earn per tap</>
    }
  ];

  return (
    <div className="font-rubik">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        toastStyle={{
          background: "#000",
          color: "#fff"
        }}
        progressStyle={{
          background: "#FFA500"
        }}
      />
      <div className="_container_1xx37_1">
        <div style={{ height: '85vh', overflow: 'visible' }}>
          <div className="_tasks_1xx37_777 scrollable">
            <div className="_tasks__content_1xx37_805">
              <div className="_tasks__content__top_1xx37_813">
                <div className="tabs">
                  <div className="tab activeTab">Gem Exchange</div>
                </div>
              </div>
              <div className="_tasks__content__bottom_1xx37_897">
                <p className="_tasks__content__bottom__title_1xx37_902 font-rubik">Available Exchanges</p>
                <div className="_tasks__content__tasks_1xx37_908">
                  {exchanges.map((exchange) => (
                    <div key={exchange.id} className="_tasks__content__tasks__item_1xx37_919">
                      <button 
                        className="_tasks__content__tasks__item_1xx37_919"
                        onClick={() => {
                          setSelectedExchange(exchange);
                          handleHapticFeedback();
                        }}
                      >
                        <div className="_tasks__content__tasks__item__icon_1xx37_955">
                          <img src={exchange.icon} alt={`Gem ${exchange.id}`} style={{ width: '24px', height: '24px' }} />
                        </div>
                        <div className="_tasks__content__tasks__item__body_1xx37_970">
                          <p className="_tasks__content__tasks__item__body__title_1xx37_980 font-rubik">{exchange.title}</p>
                         
                          <div className="_tasks__content__tasks__item__body__points_1xx37_990 font-rubik">
                            <p>{exchange.exchangeRate}</p>
                          </div>
                        </div>
                        <p className="_tasks__content__tasks__item__arrowContainer_1xx37_936">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.6">
                              <path d="M7.5 4.16699L12.5 10.0003L7.5 15.8337" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        </p>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedExchange && (
        <div className="modalOverlay">
          <div className="modalContent">
            <div className="modalHeader">
              <h2>{selectedExchange.title}</h2>
              <button className="closeButton" onClick={() => setSelectedExchange(null)}>×</button>
            </div>
            <div className="modalBody">
              <div className="taskModalDetails">
                <div className="taskModalDescriptionTitle">What does this exchange do?</div>
                <div className="taskModalDescription">{selectedExchange.description}</div>
              </div>
              <div className="taskModalReward">
                <div className="taskModalRewards">
                  <div className="taskModalRewardsLabel">Required Gems</div>
                  <div className="taskModalRewardsValue" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={selectedExchange.icon} alt={`Gem ${selectedExchange.id}`} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                    <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{selectedExchange.requiredGems}</span>
                  </div>
                </div>
              </div>
              <button 
                className={`startButton ${selectedExchange.currentValue < selectedExchange.requiredGems ? 'disabled' : ''}`}
                onClick={() => {
                  selectedExchange.action();
                  handleHapticFeedback();
                }}
                style={{
                  backgroundColor: selectedExchange.currentValue < selectedExchange.requiredGems ? '#FF4136' : '#FFA500',
                  cursor: 'pointer'
                }}
                disabled={selectedExchange.currentValue < selectedExchange.requiredGems}
              >
                {selectedExchange.currentValue < selectedExchange.requiredGems
                  ? `Not enough gems (${selectedExchange.currentValue}/${selectedExchange.requiredGems})`
                  : 'Exchange Gems'
                }
              </button>
            </div>
          </div>
        </div>
      )}
      <AppBar />
    </div>
  );
}

export default GemExchange;