import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const DailyRewards = () => {
  const [currentDay, setCurrentDay] = useState(0);
  const [canClaim, setCanClaim] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const rewards = [
    { day: 1, amount: 1500 },
    { day: 2, amount: 3000 },
    { day: 3, amount: 5000 },
    { day: 4, amount: 7000 },
    { day: 5, amount: 10000 },
    { day: 6, amount: 15000 },
    { day: 7, amount: 30000 },
    { day: 8, amount: 50000 },
    { day: 9, amount: 70000 },
    { day: 10, amount: 100000 },
    { day: 11, amount: 1000000 },
    { day: 12, amount: 5000000 },
  ];

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return num % 1000000 === 0 ? (num / 1000000) + 'M' : (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return num % 1000 === 0 ? (num / 1000) + 'K' : (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
  };

  useEffect(() => {
    if (window.Telegram?.WebApp?.initDataUnsafe?.user) {
      const telegramId = window.Telegram.WebApp.initDataUnsafe.user.id;
      fetchUserToken(telegramId);
    }

    // Setup Telegram WebApp back button
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButtonClick);
    }

    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButtonClick);
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, []);

  useEffect(() => {
    if (userToken) {
      fetchDailyRewardStatus();
    }
  }, [userToken]);

  const handleBackButtonClick = () => {
    navigate('/');
  };

  const fetchUserToken = async (telegramId) => {
    try {
      const response = await axios.post(`${backendUrl}/request_token`, { telegramId });
      if (response.data.success) {
        setUserToken(response.data.token);
      } else {
        console.error('Failed to fetch user token:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching user token:', error);
    }
  };

  const fetchDailyRewardStatus = async () => {
    try {
      const response = await axios.post(`${backendUrl}/daily_reward_status`, { token: userToken });
      setCurrentDay(response.data.currentDay);
      setCanClaim(response.data.canClaim);
    } catch (error) {
      console.error('Error fetching daily reward status:', error);
    }
  };

  const claimReward = async () => {
    if (!canClaim || !userToken) return;

    try {
      if (window.Telegram?.WebApp?.HapticFeedback) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      }

      const response = await axios.post(`${backendUrl}/claim_daily_reward`, { token: userToken });
      if (response.data.success) {
        setClaimed(true);
        setCanClaim(false);
        toast.success('Daily reward claimed successfully!');
        // Update user balance based on response.data.balance
        // You might want to update the user's balance in the parent component or global state here
      }
    } catch (error) {
      console.error('Error claiming daily reward:', error);
      toast.error('Failed to claim daily reward. Please try again.');
    }
  };

  return (
    <div className="bottom-sheet-inner">
      <div className="bottom-sheet-close" onClick={handleBackButtonClick}>
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{enableBackground: 'new 0 0 32 32'}} viewBox="0 0 32 32">
            <path d="" fill="currentColor" />
          </svg>
        </div>
      </div>
      <div className="bottom-sheet-scroll">
        <div className="bs-content">
          <div className="bs-content-image is-boost">
            <div className="earn-image">
              <picture>
                <source srcSet="/images/icons/gift.png" type="image/webp" alt="streak_days" />
                <img className="img-responsive" src="/images/icons/gift.png" alt="streak_days" />
              </picture>
            </div>
          </div>
          <div className="bs-content-title">Daily reward</div>
          <div className="bs-content-description">Accrue coins for logging into the game daily without skipping</div>
          <ul className="bs-content-daily">
            {rewards.map((reward, index) => (
              <li key={reward.day} className={`${index + 1 === currentDay ? 'is-current' : ''} ${index + 1 > currentDay ? 'is-disabled' : ''}`}>
                <span>Day {reward.day}</span>
                <div className="bs-content-daily-coin">
                  <img className="coin img-responsive" src="/images/icons/coin.png" alt="coin" />
                </div>
                <p>{formatNumber(reward.amount)}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button 
        className={`bottom-sheet-button button button-primary button-large is-sticky ${!canClaim || claimed ? 'is-disabled' : ''}`}
        onClick={claimReward}
        disabled={!canClaim || claimed}
      >
        <span>{claimed ? 'Claimed' : 'Claim'}</span>
      </button>
    </div>
  );
};

export default DailyRewards;