import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from './app-bar';
import { useUser } from './UserContext';
import './styles/bootstrap.css';
import './fonts/css/fontawesome-all.min.css';
import { getTranslation } from './translations';

function BalanceLeaders() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user } = useUser();
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://fast.digitaldollscrypto.art';
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [theme] = useState('theme-dark');

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.BackButton.show();

      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/leaderboard');
      });

      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.language_code) {
        const language = telegramUser.language_code.toLowerCase().startsWith('ru') ? 'ru' : 'en';
        setCurrentLanguage(language);
      }
    }
  }, [navigate]);

  const fetchLeaderboardData = useCallback(async () => {
    if (!user?.id) {
      console.error(getTranslation(currentLanguage, 'userIdNotFound'));
      setError(getTranslation(currentLanguage, 'userIdNotFound'));
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/balance_leaderboard`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telegramId: user.id })
      });

      if (!response.ok) {
        throw new Error(`${getTranslation(currentLanguage, 'error')}: ${response.status}`);
      }

      const data = await response.json();
      console.log('Balance Leaderboard data:', data); // Отладочный вывод
      if (data.success) {
        setLeaderboardData(data.leaderboard);
        setUserRank(data.userRank);
      } else {
        console.error(getTranslation(currentLanguage, 'failedToFetchLeaderboard'), data.error);
        setError(`${getTranslation(currentLanguage, 'failedToFetchLeaderboard')}: ${data.error}`);
      }
    } catch (err) {
      console.error(getTranslation(currentLanguage, 'fetchError'), err);
      setError(`${getTranslation(currentLanguage, 'fetchError')}: ${err.message}`);
    }
  }, [backendUrl, user, currentLanguage]);

  useEffect(() => {
    fetchLeaderboardData();
  }, [fetchLeaderboardData]);

  const formatBalance = (balance) => {
    if (balance === undefined || balance === null || isNaN(balance)) {
      return '0';
    }
    if (balance >= 1000000000) {
      return `${(balance / 1000000000).toFixed(1)}B`;
    } else if (balance >= 1000000) {
      return `${(balance / 1000000).toFixed(1)}M`;
    } else if (balance >= 1000) {
      return `${(balance / 1000).toFixed(1)}K`;
    }
    return balance.toString();
  };

  const renderLeaderboardItem = (player, index, showRealRank = false) => (
    <div key={player.telegram_id || index} className="d-flex align-items-center mb-3">
      <div className="me-3 rounded-circle bg-theme" style={{width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h5 className="mb-0" style={{color: index < 3 ? ['#FFD700', '#C0C0C0', '#CD7F32'][index] : '#FFFFFF'}}>
          {showRealRank ? player.rank : index + 1}
        </h5>
      </div>
      <span>
        {(player.first_name || player.username || player.telegram_id).substring(0, 15)}
      </span>
      {showRealRank && (
        <span className="ms-2 opacity-50">({getTranslation(currentLanguage, 'realRank')}: {player.rank})</span>
      )}
      <strong className="color-white ms-auto d-flex align-items-center">
        {formatBalance(player.balance)} 
        <img src="/images/icons/coin.png" alt="Coin" className="ms-1" style={{width: '20px', height: '20px'}} />
      </strong>
    </div>
  );

  return (
    <div className={theme}>
      <div id="page">
      <div className="divider mb-3"></div>
        <div className="page-content">
          <div className="card card-style" style={{backgroundImage: 'url("/images1/balanceleaders.jpg")', minHeight: '300px'}}>
            <div className="card-top px-3 py-3">
              <h1 className="color-white">{getTranslation(currentLanguage, 'balanceLeaderboard')}</h1>
            </div>
            <div className="card-overlay bg-gradient opacity-30"></div>
            <div className="card-overlay bg-gradient"></div>
          </div>

          <div className="card card-style mt-4">
            <div className="content">
              <h5 className="font-14 opacity-50">
                {getTranslation(currentLanguage, 'topBalances')}
              </h5>
              <div className="divider mb-3"></div>
              <div className="list-group list-custom-small list-menu ms-0 me-1">
                {leaderboardData.map((player, index) => renderLeaderboardItem(player, index))}
                
                {userRank && userRank.rank > 100 && (
                  <>
                    <div className="divider my-3"></div>
                    {renderLeaderboardItem({...userRank, telegram_id: user.id}, 100, true)}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppBar language={currentLanguage} />
    </div>
  );
}

export default BalanceLeaders;