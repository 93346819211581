import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppBar from './app-bar';
import { useUser } from './UserContext';
import './leaderboard.css';
import './tasks.css';
import { getTranslation } from './translations';

import { ReactComponent as EnergyIcon } from './assets/energy.svg';
import { ReactComponent as TapIcon } from './assets/tap.svg';
import { ReactComponent as IncomeIcon } from './assets/income.svg';

const formatNumber = (num) => {
  if (num >= 1000000) return `${Math.floor(num / 1000000)}M`;
  if (num >= 1000) return `${Math.floor(num / 1000)}K`;
  return Math.floor(num).toString();
};

function InnerBoosts() {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [userData, setUserData] = useState({
    clicks_remaining: 0,
    max_clicks: 2000,
    earn_per_tap: 1,
    earn_per_tap_price: 1000,
    passive_earn: 0,
    passive_earn_price: 500000,
    balance: 0,
    gem1_balance: 0,
    gem2_balance: 0,
    gem3_balance: 0,
    earn_per_tap_boosts: 0,
    passive_earn_boosts: 0,
  });
  const [selectedBoost, setSelectedBoost] = useState(null);
  const [energyBoostsUsed, setEnergyBoostsUsed] = useState(0);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const tokenRef = useRef(null);
  const fetchTimeoutRef = useRef(null);
  const [currentLanguage, setCurrentLanguage] = useState('en');

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.language_code) {
        const language = telegramUser.language_code.toLowerCase().startsWith('ru') ? 'ru' : 'en';
        setCurrentLanguage(language);
      }
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    if (!user?.id || tokenRef.current) return;

    try {
      const tokenResponse = await axios.post(`${backendUrl}/request_token`, { telegramId: user.id });
      if (!tokenResponse.data.success) {
        throw new Error(getTranslation(currentLanguage, 'failedToFetchUserToken'));
      }

      tokenRef.current = tokenResponse.data.token;

      const [userDataResponse, energyBoostsResponse] = await Promise.all([
        axios.post(`${backendUrl}/get_user_data`, { token: tokenRef.current }),
        axios.post(`${backendUrl}/get_energy_boosts_used`, { token: tokenRef.current })
      ]);

      if (!userDataResponse.data.success || !energyBoostsResponse.data.success) {
        throw new Error(getTranslation(currentLanguage, 'failedToFetchUserData'));
      }

      const newUserData = {
        ...userDataResponse.data.user,
        token: tokenRef.current
      };

      setUserData(prevData => ({ ...prevData, ...newUserData }));
      setEnergyBoostsUsed(energyBoostsResponse.data.energyBoostsUsed);
      
      setUser(prevUser => ({
        ...prevUser,
        ...newUserData,
        displayName: prevUser.displayName || newUserData.first_name || newUserData.username,
        first_name: prevUser.first_name || newUserData.first_name,
        username: prevUser.username || newUserData.username,
        id: prevUser.id
      }));
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error(getTranslation(currentLanguage, 'failedToLoadUserData'));
    }
  }, [backendUrl, user?.id, setUser, currentLanguage]);

  useEffect(() => {
    if (user?.id && !tokenRef.current) {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
      fetchTimeoutRef.current = setTimeout(fetchUserData, 1000);
    }
    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [user?.id, fetchUserData]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate(-1);
      });
    }
  
    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, [navigate]);
  
  const handleHapticFeedback = () => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };

  const handleEnergyBoost = async () => {
    try {
      if (energyBoostsUsed >= 3) {
        toast.error(getTranslation(currentLanguage, 'energyBoostLimitReached'));
        return;
      }

      const response = await axios.post(`${backendUrl}/reset_clicks`, { token: userData.token });
      if (response.data.success) {
        setUserData(prevData => ({
          ...prevData,
          clicks_remaining: response.data.clicksRemaining
        }));
        setEnergyBoostsUsed(response.data.energyBoostsUsed);
        toast.success(getTranslation(currentLanguage, 'energyRecharged'));
        navigate('/');
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      toast.error(getTranslation(currentLanguage, 'errorResettingClicks'));
    }
  };

  const handleEarnPerTapBoost = async () => {
    try {
      const response = await axios.post(`${backendUrl}/boost_earn_per_tap`, { token: userData.token });
      if (response.data.success) {
        setUserData(prevData => ({
          ...prevData,
          earn_per_tap: response.data.new_earn_per_tap,
          earn_per_tap_price: response.data.new_price,
          balance: response.data.new_balance,
          earn_per_tap_boosts: response.data.boosts_used
        }));
        toast.success(getTranslation(currentLanguage, 'earnPerTapBoosted').replace('{amount}', formatNumber(response.data.new_earn_per_tap)));
        setSelectedBoost(null);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error(getTranslation(currentLanguage, 'failedToBoostEarnPerTap'));
      }
    }
  };
  
  const handlePassiveEarnBoost = async () => {
    try {
      const response = await axios.post(`${backendUrl}/boost_passive_income`, { token: userData.token });
      if (response.data.success) {
        setUserData(prevData => ({
          ...prevData,
          passive_earn: response.data.new_passive_earn,
          passive_earn_price: response.data.new_price,
          balance: response.data.new_balance,
          passive_earn_boosts: response.data.boosts_used
        }));
        toast.success(getTranslation(currentLanguage, 'passiveIncomeBoosted').replace('{amount}', formatNumber(response.data.new_passive_earn)));
        setSelectedBoost(null);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error(getTranslation(currentLanguage, 'failedToBoostPassiveIncome'));
      }
    }
  };

  const boosts = [
    {
      id: 2,
      title: getTranslation(currentLanguage, 'earnPerTapBoost'),
      icon: TapIcon,
      description: getTranslation(currentLanguage, 'increaseEarnPerTap'),
      action: handleEarnPerTapBoost,
      price: userData.earn_per_tap_price,
      currentValue: userData.earn_per_tap,
      futureValue: userData.earn_per_tap + 1,
      boosts_used: userData.earn_per_tap_boosts
    },
    {
      id: 3,
      title: getTranslation(currentLanguage, 'passiveIncomeBoost'),
      icon: IncomeIcon,
      description: getTranslation(currentLanguage, 'increasePassiveIncome'),
      action: handlePassiveEarnBoost,
      price: userData.passive_earn_price,
      currentValue: userData.passive_earn,
      futureValue: userData.passive_earn + 100,
      boosts_used: userData.passive_earn_boosts
    },
  ];

  return (
    <div className="font-rubik">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        toastStyle={{
          background: "#000",
          color: "#fff"
        }}
        progressStyle={{
          background: "#FFA500"
        }}
      />
      <div className="_container_1xx37_1">
        <div style={{ height: '85vh', overflow: 'visible' }}>
          <div className="_tasks_1xx37_777 scrollable">
            <div className="_tasks__content_1xx37_805">
              <div className="_tasks__content__top_1xx37_813">
                <div className="tabs">
                  <div className="tab activeTab">{getTranslation(currentLanguage, 'boosts')}</div>
                </div>
              </div>
              <div className="_tasks__content__bottom_1xx37_897">
                <p className="_tasks__content__bottom__title_1xx37_902 font-rubik">{getTranslation(currentLanguage, 'availableBoosts')}</p>
                <div className="_tasks__content__tasks_1xx37_908">
                  {boosts.map((boost) => (
                    <div key={boost.id} className="_tasks__content__tasks__item_1xx37_919">
                      <button 
                        className="_tasks__content__tasks__item_1xx37_919"
                        onClick={() => {
                          setSelectedBoost(boost);
                          handleHapticFeedback();
                        }}
                      >
                        <div className="_tasks__content__tasks__item__icon_1xx37_955">
                          <boost.icon style={{ fill: '#FFFFFF', width: '24px', height: '24px' }} />
                        </div>
                        <div className="_tasks__content__tasks__item__body_1xx37_970">
                          <p className="_tasks__content__tasks__item__body__title_1xx37_980 font-rubik">{boost.title}</p>
                          <div className="_tasks__content__tasks__item__body__points_1xx37_990 font-rubik">
                            {boost.price ? (
                              <>
                                <img src="/images/icons/coin.png" alt="Coin" />
                                <p>{formatNumber(boost.price)}</p>
                              </>
                            ) : (
                              <p>{boost.usageInfo}</p>
                            )}
                            {boost.currentValue !== undefined && boost.futureValue !== undefined && (
                              <span>
                                • {formatNumber(boost.currentValue)} → {formatNumber(boost.futureValue)}/{getTranslation(currentLanguage, boost.id === 2 ? 'tap' : 'hour')}
                                {(boost.id === 2 || boost.id === 3) && ` • ${getTranslation(currentLanguage, 'boosts')}: ${boost.boosts_used}/10`}
                              </span>
                            )}
                          </div>
                        </div>
                        <p className="_tasks__content__tasks__item__arrowContainer_1xx37_936">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.6">
                              <path d="M7.5 4.16699L12.5 10.0003L7.5 15.8337" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                          </svg>
                        </p>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      {selectedBoost && (
        <div className="modalOverlay">
          <div className="modalContent">
            <div className="modalHeader">
              <h2>{selectedBoost.title}</h2>
              <button className="closeButton" onClick={() => setSelectedBoost(null)}>×</button>
            </div>
            <div className="modalBody">
              <div className="taskModalDetails">
                <div className="taskModalDescriptionTitle">{getTranslation(currentLanguage, 'whatDoesThisBoostDo')}</div>
                <div className="taskModalDescription">{selectedBoost.description}</div>
              </div>
              <div className="taskModalReward">
                {selectedBoost.price ? (
                  <>
                    <img src="/images/icons/coin.png" className="taskModalCoin" alt="Coin" />
                    <div className="taskModalRewards">
                      <div className="taskModalRewardsLabel">{getTranslation(currentLanguage, 'price')}</div>
                      <div className="taskModalRewardsValue">{formatNumber(selectedBoost.price)}</div>
                    </div>
                  </>
                ) : (
                  <div className="taskModalRewards">
                    <div className="taskModalRewardsLabel">{getTranslation(currentLanguage, 'usage')}</div>
                    <div className="taskModalRewardsValue">{selectedBoost.usageInfo}</div>
                  </div>
                )}
              </div>
              <button 
                className={`startButton ${
                  (selectedBoost.price && userData.balance < selectedBoost.price) ||
                  (selectedBoost.id === 1 && energyBoostsUsed >= 3) ||
                  ((selectedBoost.id === 2 || selectedBoost.id === 3) && selectedBoost.boosts_used >= 10)
                    ? 'disabled'
                    : ''
                }`}
                onClick={() => {
                  selectedBoost.action();
                  handleHapticFeedback();
                }}
                style={{
                  backgroundColor: 
                    (selectedBoost.price && userData.balance < selectedBoost.price) ||
                    (selectedBoost.id === 1 && energyBoostsUsed >= 3) ||
                    ((selectedBoost.id === 2 || selectedBoost.id === 3) && selectedBoost.boosts_used >= 10)
                      ? '#FF4136'  // Красный цвет для неактивной кнопки
                      : '#FFA500', // Оранжевый цвет для активной кнопки
                  cursor: 'pointer'
                }}
                disabled={
                  (selectedBoost.price && userData.balance < selectedBoost.price) ||
                  (selectedBoost.id === 1 && energyBoostsUsed >= 3) ||
                  ((selectedBoost.id === 2 || selectedBoost.id === 3) && selectedBoost.boosts_used >= 10)
                }
              >
                {selectedBoost.price && userData.balance < selectedBoost.price
                  ? getTranslation(currentLanguage, 'notEnoughCoins').replace('{balance}', formatNumber(userData.balance)).replace('{price}', formatNumber(selectedBoost.price))
                  : selectedBoost.id === 1 && energyBoostsUsed >= 3
                  ? getTranslation(currentLanguage, 'energyBoostLimitReached')
                  : (selectedBoost.id === 2 || selectedBoost.id === 3) && selectedBoost.boosts_used >= 10
                  ? getTranslation(currentLanguage, 'maximumBoostsReached')
                  : getTranslation(currentLanguage, 'activateBoost')}
              </button>
            </div>
          </div>
        </div>
      )}
      <AppBar language={currentLanguage} />
    </div>
  );
}

export default InnerBoosts;