import React, { useState, useEffect } from 'react';

const PassiveEarn = ({ token, onClose, onUpdateBalance }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [passiveIncome, setPassiveIncome] = useState(0);
  const [elapsedTime, setElapsedTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleClaim = () => {
    // Trigger haptic feedback
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    handleClose();
  };

  useEffect(() => {
    const fetchPassiveIncome = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_passive_income`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        });
        const data = await response.json();
        if (data.success) {
          setPassiveIncome(data.passiveIncome);
          if (onUpdateBalance) {
            onUpdateBalance(data.newBalance);
          }
          const hours = Math.floor(data.elapsedTime / 3600);
          const minutes = Math.floor((data.elapsedTime % 3600) / 60);
          setElapsedTime(`${hours}h ${minutes}m`);
        } else {
          setError(data.error);
        }
      } catch (error) {
        setError('Failed to fetch passive income');
      } finally {
        setLoading(false);
      }
    };
  
    fetchPassiveIncome();
  }, [token, onUpdateBalance]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="bottom-sheet open">
      <div className="bottom-sheet-inner">
        <div className="bottom-sheet-close" onClick={handleClose}>
          {/* Close button SVG */}
        </div>
        <div className="bottom-sheet-scroll">
          <div className="bs-passive">
            <div className="bs-passive-inner">
              <div className="bs-passive-exchange">
                {/* Exchange image */}
              </div>
              <div className="price">
                <div className="price-image">
                  {/* Coin image */}
                </div>
                {loading ? (
                  <div className="price-value">Loading...</div>
                ) : error ? (
                  <div className="price-value error">{error}</div>
                ) : (
                  <div className="price-value">+{passiveIncome.toLocaleString()}</div>
                )}
              </div>
              <p>You earned while away</p>
            </div>
            <button 
              className="bottom-sheet-button button button-primary button-large" 
              onClick={handleClaim}
            >
              <span>Claim</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassiveEarn;