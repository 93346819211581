import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getTranslation } from './translations';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTasks, faUsers, faRocket, faTrophy, faDiamond, faStore } from '@fortawesome/free-solid-svg-icons';

const AppBar = ({ language }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState('/');

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const handleHapticFeedback = () => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };

  const isActive = (path) => activePath === path;

  return (
    <div id="footer-bar" className={styles.footerBar6}>

<Link to="/" onClick={handleHapticFeedback} className={isActive('/') ? styles.activeNav : ''}>
        <FontAwesomeIcon icon={faHome} />
        <span>{getTranslation(language, 'main')}</span>
      </Link>
      <Link to="/tasks" onClick={handleHapticFeedback} className={isActive('/tasks') ? styles.activeNav : ''}>
        <FontAwesomeIcon icon={faTasks} />
        <span>{getTranslation(language, 'tasks')}</span>
      </Link>
    
      <Link to="/friends" onClick={handleHapticFeedback} className={isActive('/friends') ? styles.activeNav : ''}>
        <FontAwesomeIcon icon={faUsers} />
        <span>{getTranslation(language, 'friends')}</span>
      </Link>
    
      <Link to="/gem-mining" onClick={handleHapticFeedback} className={isActive('/gem-mining') ? styles.activeNav : ''}>
        <FontAwesomeIcon icon={faDiamond} />
        <span>{getTranslation(language, 'gemMining')}</span>
      </Link> 
      <Link to="/Market" onClick={handleHapticFeedback} className={isActive('/Market') ? styles.activeNav : ''}>
        <FontAwesomeIcon icon={faStore} />
        <span>{getTranslation(language, 'market')}</span>
      </Link>
      <Link to="/leaderboard" onClick={handleHapticFeedback} className={isActive('/leaderboard') ? styles.activeNav : ''}>
        <FontAwesomeIcon icon={faTrophy} />
        <span>{getTranslation(language, 'leaderboard')}</span>
      </Link>
    </div>
  );
};

export default AppBar;