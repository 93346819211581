import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import AppBar from './app-bar';
import { getTranslation } from './translations'; // Импортируем функцию getTranslation

const GemMining = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const [gemBalances, setGemBalances] = useState({ gem1: 0, gem2: 0, gem3: 0 });
  const [userToken, setUserToken] = useState(localStorage.getItem('userToken'));
  const [userData, setUserData] = useState(null);
  const [initialUserData, setInitialUserData] = useState(null);
  const [pendingGems, setPendingGems] = useState({ gem1: 0, gem2: 0, gem3: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [buttonTransform, setButtonTransform] = useState({
    scale: 1,
    translateZ: 0,
    rotateX: 0,
    rotateY: 0
  });

  const [animations, setAnimations] = useState([]);
  const [rareGemAnimations, setRareGemAnimations] = useState([]);
  const gemRef = useRef(null);
  const saveTimeoutRef = useRef(null);
  const clickCountRef = useRef(0);

  const formatNumber = (num) => {
    return num.toString();
  };

  const handleHapticFeedback = () => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
  };

  const handleExchangeClick = () => {
    handleHapticFeedback();
    navigate('/gem-exchange');
  };

  const updateButtonTransform = (event) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
  
    const rotateX = ((y - centerY) / centerY) * 10;
    const rotateY = ((centerX - x) / centerX) * 10;
  
    setButtonTransform({
      scale: 0.98,
      translateZ: 5,
      rotateX,
      rotateY
    });
  };
  
  const resetButtonTransform = () => {
    setButtonTransform({
      scale: 1,
      translateZ: 0,
      rotateX: 0,
      rotateY: 0
    });
  };

  const addRareGemAnimation = (gemType) => {
    const newAnimation = {
      id: Date.now(),
      gemType,
      gems: Array(20).fill().map((_, i) => ({
        key: `${Date.now()}-${i}`,
        left: `${Math.random() * 100}%`,
        delay: Math.random() * 0.5,
        duration: 2 + Math.random() * 1
      }))
    };

    setRareGemAnimations(prev => [...prev, newAnimation]);

    setTimeout(() => {
      setRareGemAnimations(prev => prev.filter(anim => anim.id !== newAnimation.id));
    }, 3000);
  };

  const handleGemClick = (event) => {
    if (userData) {
      clickCountRef.current += 1;
      if (clickCountRef.current > 200) {
        saveGems();
        clickCountRef.current = 0;
      }

      const random = Math.random() * 100;
      let gem = null;
      if (random < userData.gem3_chance) {
        gem = 'gem3';
        if (window.Telegram?.WebApp?.HapticFeedback) {
          window.Telegram.WebApp.HapticFeedback.notificationOccurred('success');
        }
        addRareGemAnimation('gem3');
      } else if (random < userData.gem3_chance + userData.gem2_chance) {
        gem = 'gem2';
        if (window.Telegram?.WebApp?.HapticFeedback) {
          window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
        }
        addRareGemAnimation('gem2');
      } else if (random < userData.gem3_chance + userData.gem2_chance + userData.gem1_chance) {
        gem = 'gem1';
      }

      const x = event.clientX;
      const y = event.clientY;

      if (gem) {
        setGemBalances(prev => ({ ...prev, [gem]: prev[gem] + 1 }));
        setPendingGems(prev => ({ ...prev, [gem]: prev[gem] + 1 }));

        setAnimations(prevAnimations => [
          ...prevAnimations.slice(-20),
          {
            id: Date.now(),
            value: <img src={`/images/gems/${gem}.png`} alt={gem} style={{width: '30px', height: '30px'}} />,
            x: x,
            y: y,
          },
        ]);
      } else {
        setAnimations(prevAnimations => [
          ...prevAnimations.slice(-20),
          {
            id: Date.now(),
            value: '⛏️',
            x: x,
            y: y,
          },
        ]);
      }

      if (window.Telegram?.WebApp?.HapticFeedback) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      }

      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
      saveTimeoutRef.current = setTimeout(saveGems, 3000);
    }
  };

  const fetchUserData = async () => {
    if (userToken) {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_user_gem_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: userToken }),
        });
        if (!response.ok) throw new Error(`Failed to fetch user data: ${response.status}`);
        const data = await response.json();
        if (data) {
          setUserData(data.user);
          setGemBalances({
            gem1: data.user.gem1_balance,
            gem2: data.user.gem2_balance,
            gem3: data.user.gem3_balance,
          });
        }
      } catch (error) {
        console.error('Error fetching user gem data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const saveGems = async () => {
    if (Object.values(pendingGems).some(val => val > 0)) {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/save_gems`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: userToken, gems: pendingGems }),
        });
        if (!response.ok) throw new Error(`Network response was not ok: ${response.status}`);
        const data = await response.json();
        if (data.success) {
          setPendingGems({ gem1: 0, gem2: 0, gem3: 0 });
        }
      } catch (error) {
        console.error('Error saving gems:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!userToken) {
      const storedToken = localStorage.getItem('userToken');
      if (storedToken) {
        setUserToken(storedToken);
      } else {
        // Если токен не найден, возможно, стоит перенаправить пользователя на главную страницу
        navigate('/');
      }
    }

    if (window.Telegram?.WebApp) {
      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.language_code) {
        const language = telegramUser.language_code.toLowerCase().startsWith('ru') ? 'ru' : 'en';
        setCurrentLanguage(language);
      }
    }
 
  }, []);
  
  useEffect(() => {
    if (userToken) {
      fetchUserData();
    }
  }, [userToken]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.BackButton.show();

      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/');
      });

      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser) {
        const displayName = telegramUser.first_name || telegramUser.username || telegramUser.id.toString();
        setInitialUserData({
          displayName: displayName,
          photo_url: telegramUser.photo_url,
        });

        if (!userToken) {
          fetch(`${process.env.REACT_APP_BACKEND_URL}/request_token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              telegramId: telegramUser.id, 
              username: telegramUser.username,
              firstName: telegramUser.first_name
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                setUserToken(data.token);
                localStorage.setItem('userToken', data.token);
                setUserData({
                  displayName: displayName,
                  photo_url: telegramUser.photo_url,
                });
              }
            })
            .catch((error) => console.error('Error getting user token:', error));
        }
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <main className={`main gem-mining ${location.pathname === '/gem-mining' ? 'gem-mining-bg' : ''}`}>
      <header className="header">
        <div className="header-left">
          <div className="user-info">
            <div className="user-info-avatar">
              <img src={userData?.photo_url || initialUserData?.photo_url || "/images/avatar.jpg"} alt="Avatar" />
            </div>
            <p>{userData?.displayName || initialUserData?.displayName || 'User'} <span></span></p>
          </div>
        </div>
        <div className="header-right">
          {/* Здесь можно добавить дополнительную информацию */}
        </div>
      </header>
      <div className="content is-main has-glow">
        <u1l className="user-stats">
          <li>
            <div className="user-stats-item">
              <p className="text"></p> 
              <div className="price">
                <div className="price-image">
                  <img className="gem img-responsive is-20" src="/images/gems/gem1.png" alt="Gem 1" />
                </div>
                <div className="price-value">{formatNumber(gemBalances.gem1)}</div>
              </div>
            </div>
            <div className="user-stats-item">
              <p className="text"></p> 
              <div className="price">
                <div className="price-image">
                  <img className="gem img-responsive is-20" src="/images/gems/gem2.png" alt="Gem 2" />
                </div>
                <div className="price-value">{formatNumber(gemBalances.gem2)}</div>
              </div>
            </div>
            <div className="user-stats-item">
              <p className="text"></p> 
              <div className="price">
                <div className="price-image">
                  <img className="gem img-responsive is-20" src="/images/gems/gem3.png" alt="Gem 3" />
                </div>
                <div className="price-value">{formatNumber(gemBalances.gem3)}</div>
              </div>
            </div>
          </li>
        </u1l>
        <div className="user-tap has-gap">
          <button
            className="user-tap-button button"
            onPointerDown={(e) => {
              handleGemClick(e);
              updateButtonTransform(e);
            }}
            onPointerUp={resetButtonTransform}
            onPointerLeave={resetButtonTransform}
            disabled={isLoading}
            ref={gemRef}
          >
            <div 
              className="user-tap-button-inner"
              style={{
                transform: `
                  scale(${buttonTransform.scale})
                  translateZ(${buttonTransform.translateZ}px)
                  rotateX(${buttonTransform.rotateX}deg)
                  rotateY(${buttonTransform.rotateY}deg)
                `,
              }}
            >
              <img 
                src="/images/gemminer.png" 
                alt="Gem Button" 
                className="gem-button-image"
              />
            </div>
          </button>
          <div className="user-tap-animate" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
            <AnimatePresence>
              {animations.map((animation) => (
                <motion.div
                  key={animation.id}
                  className="clickAmount"
                  initial={{ opacity: 1, y: animation.y, x: animation.x, scale: 1 }}
                  animate={{ 
                    opacity: 0, 
                    y: animation.y - 150,
                    scale: 1.5
                  }}
                  exit={{ opacity: 0 }}
                  transition={{ 
                    duration: 0.8,
                    ease: "easeOut"
                  }}
                  style={{ 
                    position: 'absolute',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    filter: 'drop-shadow(0 0 2px rgba(0,0,0,0.5))'
                  }}
                >
                  {animation.value}
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          {rareGemAnimations.map((animation) => (
            <div key={animation.id} className="rare-gem-animation" style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: 'none',
              overflow: 'hidden'
            }}>
              {animation.gems.map((gem) => (
                <motion.img
                  key={gem.key}
                  src={`/images/gems/${animation.gemType}.png`}
                  alt={animation.gemType}
                  style={{
                    position: 'absolute',
                    width: '30px',
                    height: '30px',
                    left: gem.left,
                    top: '-30px'
                  }}
                  initial={{ y: '-100%' }}
                  animate={{ y: '100vh' }}
                  transition={{
                    duration: gem.duration,
                    ease: 'linear',
                    delay: gem.delay
                  }}
                />
              ))}
            </div>
          ))}
        </div>
        <div className="exchange-button-container">
        <button className="exchange-button" onClick={handleExchangeClick}>
        {getTranslation(currentLanguage, 'exchange')}
        </button>
      </div>
      </div>
      <AppBar language={currentLanguage} />
    </main>
  );
};

export default GemMining;