import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppBar from './app-bar';
import './leaderboard.css';
import './tasks.css';
import { getTranslation } from './translations';

// Импорт SVG иконок
import { ReactComponent as FriendIcon } from './/assets/friend.svg';
import { ReactComponent as InstagramIcon } from './/assets/instagram.svg';
import { ReactComponent as TelegramIcon } from './/assets/telegram.svg';

function Tasks() {
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState(null);
  const [invitedFriends, setInvitedFriends] = useState(0);
  const [userToken, setUserToken] = useState(localStorage.getItem('userToken'));
  const [completedTasks, setCompletedTasks] = useState([]);
  const [activeTab, setActiveTab] = useState('Tasks');
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const telegramChannelLink = 'https://t.me/+_44TgGZQV_hkZmEy'; 

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.language_code) {
        const language = telegramUser.language_code.toLowerCase().startsWith('ru') ? 'ru' : 'en';
        setCurrentLanguage(language);
      }
    }
  }, []);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate(-1);
      });
    }
  
    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, [navigate]);
  
  useEffect(() => {
    if (!userToken) {
      const storedToken = localStorage.getItem('userToken');
      if (storedToken) {
        setUserToken(storedToken);
      } else {
        navigate('/');
      }
    }
  
    if (userToken) {
      fetchUserData();
      fetchCompletedTasks(userToken);
    }
  }, [userToken, navigate]);

  const handleHapticFeedback = () => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };

  const fetchUserData = async () => {
    if (userToken) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_user_data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: userToken }),
        });
        const data = await response.json();
        if (data.success) {
          setInvitedFriends(data.user.invited_friends);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  const fetchCompletedTasks = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/completed_tasks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (data.success) {
        setCompletedTasks(data.completedTasks);
      }
    } catch (error) {
      console.error('Error fetching completed tasks:', error);
    }
  };

  const tasks = [
    {
      id: 1,
      title: getTranslation(currentLanguage, 'joinTelegramChannel'),
      icon: TelegramIcon,
      points: 100000,
      featured: true,
      description: getTranslation(currentLanguage, 'telegramChannelDescription'),
      action: () => setShowTelegramModal(true)
    },
    {
      id: 2,
      title: getTranslation(currentLanguage, 'followInstagram'),
      icon: InstagramIcon,
      points: 100000,
      featured: true,
      description: getTranslation(currentLanguage, 'instagramDescription'),
      action: () => window.open('https://www.instagram.com/raventapcoin', '_blank')
    },
    {
      id: 3,
      title: getTranslation(currentLanguage, 'inviteFriends'),
      icon: FriendIcon,
      points: 500000,
      featured: true,
      description: getTranslation(currentLanguage, 'inviteFriendsDescription'),
      specialCheck: () => invitedFriends >= 3
    }
  ];

  const openModal = (task) => {
    setSelectedTask(task);
    handleHapticFeedback();
  };

  const closeModal = () => {
    setSelectedTask(null);
    handleHapticFeedback();
  };

  const handleStartTask = async (task) => {
    handleHapticFeedback();
  
    if (task.id === 1) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check_subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: userToken }),
        });
        const data = await response.json();
        
        if (data.success) {
          if (data.subscribed) {
            handleCompleteTask(task);
          } else {
            toast.error(getTranslation(currentLanguage, 'subscribeToTelegramFirst'), {
              theme: "dark",
              onOpen: handleHapticFeedback
            });
            setShowTelegramModal(true);
          }
        } else {
          toast.error(data.error || getTranslation(currentLanguage, 'failedToCheckSubscription'), {
            theme: "dark",
            onOpen: handleHapticFeedback
          });
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
        toast.error(getTranslation(currentLanguage, 'errorOccurred'), {
          theme: "dark",
          onOpen: handleHapticFeedback
        });
      }
      return;
    }

    if (completedTasks.includes(task.id)) {
      toast.info(getTranslation(currentLanguage, 'taskAlreadyCompleted'), {
        theme: "dark",
        onOpen: handleHapticFeedback
      });
      return;
    }
  
    if (task.id === 3 && invitedFriends < 3) {
      toast.error(getTranslation(currentLanguage, 'notEnoughInvitedFriends')
        .replace('{invitedFriends}', invitedFriends)
        .replace('{remaining}', 3 - invitedFriends), {
        theme: "dark",
        onOpen: handleHapticFeedback
      });
      return;
    }
  
    if (task.action) {
      task.action();
    }
  
    handleCompleteTask(task);
  };

  const handleCompleteTask = async (task) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/complete_task`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: userToken, taskId: task.id }),
      });
      const data = await response.json();
      if (data.success) {
        setCompletedTasks(data.completedTasks);
        toast.success(getTranslation(currentLanguage, 'taskCompletedEarnedCoins').replace('{points}', task.points.toLocaleString()), {
          theme: "dark",
          onOpen: handleHapticFeedback
        });
        if (task.id === 3) {
          setInvitedFriends(data.invitedFriends);
        }
        fetchUserData();
      } else {
        toast.error(data.error || getTranslation(currentLanguage, 'failedToCompleteTask'), {
          theme: "dark",
          onOpen: handleHapticFeedback
        });
        if (data.invitedFriends !== undefined) {
          setInvitedFriends(data.invitedFriends);
        }
      }
    } catch (error) {
      console.error('Error completing task:', error);
      toast.error(getTranslation(currentLanguage, 'errorOccurred'), {
        theme: "dark",
        onOpen: handleHapticFeedback
      });
    }
  
    closeModal();
  };

  const openTelegramChannel = () => {
    if (window.Telegram?.WebApp?.openTelegramLink) {
      window.Telegram.WebApp.openTelegramLink(telegramChannelLink);
    } else {
      window.open(telegramChannelLink, '_blank');
    }
    setShowTelegramModal(false);
  };

  return (
    <div className="font-rubik">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        toastStyle={{
          background: "#000",
          color: "#fff"
        }}
        progressStyle={{
          background: "#FFA500"
        }}
      />
      <div className="_container_1xx37_1">
        <div style={{ height: '85vh', overflow: 'visible' }}>
          <div className="_tasks_1xx37_777 scrollable">
            <div className="_tasks__content_1xx37_805">
              <div className="_tasks__content__top_1xx37_813">
                <div className="tabs">
                  <div 
                    className={`tab ${activeTab === 'Tasks' ? 'activeTab' : ''}`} 
                    onClick={() => {
                      setActiveTab('Tasks');
                      handleHapticFeedback();
                    }}
                  >
                    {getTranslation(currentLanguage, 'tasks')}
                  </div>
                  <div 
                    className={`tab ${activeTab === 'Daily Tasks' ? 'activeTab' : ''}`} 
                    onClick={() => {
                      setActiveTab('Daily Tasks');
                      handleHapticFeedback();
                    }}
                  >
                    {getTranslation(currentLanguage, 'dailyTasks')}
                  </div>
                </div>
              </div>
              <div className="_tasks__content__bottom_1xx37_897">
                {activeTab === 'Tasks' && (
                  <>
                    <p className="_tasks__content__bottom__title_1xx37_902 font-rubik">{getTranslation(currentLanguage, 'yourTasks')}</p>
                    <div className="_tasks__content__tasks_1xx37_908">
                      {tasks.map((task) => (
                        <div key={task.id} className="_tasks__content__tasks__item_1xx37_919">
                          <button 
                            className={`_tasks__content__tasks__item_1xx37_919 ${completedTasks.includes(task.id) ? 'completed' : ''}`} 
                            onClick={() => openModal(task)}
                          >
                            <div className="_tasks__content__tasks__item__icon_1xx37_955">
                              <task.icon style={{ fill: '#FFFFFF', width: '24px', height: '24px' }} />
                            </div>
                            <div className="_tasks__content__tasks__item__body_1xx37_970">
                              <p className="_tasks__content__tasks__item__body__title_1xx37_980 font-rubik">{task.title}</p>
                              <div className="_tasks__content__tasks__item__body__points_1xx37_990 font-rubik">
                                <img src="/images/icons/coin.png" alt="" />
                                <p>{getTranslation(currentLanguage, 'coins').replace('{amount}', task.points.toLocaleString())}</p>
                              </div>
                            </div>
                            <p className="_tasks__content__tasks__item__arrowContainer_1xx37_936">
                              {task.featured && (
                                <span 
                                  className={`_tasks__content__tasks__item__featured_1xx37_941 ${completedTasks.includes(task.id) ? 'completed' : ''}`}
                                  style={{ 
                                    backgroundColor: completedTasks.includes(task.id) ? '#4CAF50' : '#FFA500',
                                    color: 'white'
                                  }}
                                >
                                  {completedTasks.includes(task.id) ? getTranslation(currentLanguage, 'completed') : getTranslation(currentLanguage, 'featured')}
                                </span>
                              )}
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                  <path d="M7.5 4.16699L12.5 10.0003L7.5 15.8337" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </svg>
                            </p>
                          </button>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {activeTab === 'Daily Tasks' && (
                  <p className="_tasks__content__bottom__title_1xx37_902 font-rubik">{getTranslation(currentLanguage, 'dailyTasksComingSoon')}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedTask && (
        <div className="modalOverlay">
          <div className="modalContent">
            <div className="modalHeader">
              <h2>{selectedTask.title}</h2>
              <button className="closeButton" onClick={closeModal}>×</button>
            </div>
            <div className="modalBody">
              <div className="taskModalDetails">
                <div className="taskModalDescriptionTitle">{getTranslation(currentLanguage, 'whatNeedToDo')}</div>
                <div className="taskModalDescription">{selectedTask.description}</div>
              </div>
              <div className="taskModalReward">
                <img src="/images/icons/coin.png" className="taskModalCoin" alt="Coin" />
                <div className="taskModalRewards">
                  <div className="taskModalRewardsLabel">{getTranslation(currentLanguage, 'reward')}</div>
                  <div className="taskModalRewardsValue">
                    {getTranslation(currentLanguage, 'coins').replace('{amount}', selectedTask.points.toLocaleString())}
                  </div>
                </div>
              </div>
              <button 
                className={`startButton ${completedTasks.includes(selectedTask.id) ? 'completed' : ''}`}
                onClick={() => handleStartTask(selectedTask)}
                disabled={false}
                style={{
                  backgroundColor: completedTasks.includes(selectedTask.id) ? '#4CAF50' : '#FFA500',
                  cursor: 'pointer'
                }}
              >
                {completedTasks.includes(selectedTask.id) ? getTranslation(currentLanguage, 'goToChannel') : getTranslation(currentLanguage, 'start')}
              </button>
            </div>
          </div>
        </div>
      )}

      {showTelegramModal && (
        <div className="modalOverlay">
          <div className="modalContent">
            <div className="modalHeader">
              <h2>{getTranslation(currentLanguage, 'joinTelegramChannel')}</h2>
              <button className="closeButton" onClick={() => setShowTelegramModal(false)}>×</button>
            </div>
            <div className="modalBody">
              <p>{getTranslation(currentLanguage, 'joinTelegramChannelDescription')}</p>
              <button 
                className="startButton"
                onClick={openTelegramChannel}
                style={{
                  backgroundColor: '#0088cc',
                  cursor: 'pointer'
                }}
              >
                {getTranslation(currentLanguage, 'openTelegramChannel')}
              </button>
            </div>
          </div>
        </div>
      )}
      <AppBar language={currentLanguage} />
    </div>
  );
}

export default Tasks;