// translations.js

export const translations = {
    en: {
      // User info
      user: "User",
      level: "Level",
      
      // Header
      energyFree: "ENERGY FREE",
      days: "d",
      hours: "h",
      minutes: "m",
      seconds: "s",
  
      // User stats
      earnPerTap: "Earn per tap",
      coinsToLevelUp: "Coins to level up",
      profitPerHour: "Profit per hour",
  
      // Balance
      balance: "Balance",
  
      // Button and actions
      tap: "Tap",
      boost: "Boost",
  
      // Level info
      bronze: "Bronze",
      silver: "Silver",
      gold: "Gold",
      platinum: "Platinum",
      diamond: "Diamond",
      epic: "Epic",
      legendary: "Legendary",
      master: "Master",
      grandmaster: "Grandmaster",
      lord: "Lord",
  
      // Navigation
      friends: "Friends",
      tasks: "Tasks",
      dailyRewards: "Daily Rewards",
      gemMining: "Gem Mining",
      leaderboard: "Leaderboard",
      gemExchange: "Gem Exchange",
      gem1: "Gem 1",
      gem2: "Gem 2",
      gem3: "Gem 3",
      exchange: "Exchange",
  
      // Tasks
      joinTelegramChannel: "Join Our Telegram Channel",
      telegramChannelDescription: "Subscribe to the Raven channel on Telegram for the latest Pixelverse insights and get 100,000 Coins.",
      followInstagram: "Follow Our Instagram",
      instagramDescription: "Follow our Instagram account for exclusive content and earn 100,000 Coins.",
      inviteFriends: "Invite 3 Friends",
      inviteFriendsDescription: "Invite 3 friends to join Raven and receive a gift of 500,000 Coins!",
      dailyTasks: "Daily Tasks",
      yourTasks: "Your Tasks",
      dailyTasksComingSoon: "Daily Tasks Coming Soon",
      whatNeedToDo: "What need to do?",
      reward: "Reward",
      start: "Start",
      goToChannel: "Go to Channel",
      completed: "Completed",
      featured: "Featured",
      coins: "{amount} coins",
  
      // Loading
      loading: "Loading...",
  
      // Unsupported platform
      unsupportedPlatform: "Caw Caw Raven! 📱✨ To unlock the magic, open our app through the Telegram mobile app and let the adventure begin!",
  
      // AppBar
      main: "Main",
      gems: "Gems",
      boosts: "Boosts",

      // Referral
      referral: "REFERRAL",
      inviteFriendsButton: "INVITE FRIENDS",
      earnedFromReferrals: "Earned from referrals",
      score5PercentOfYourBuddies: "Score 5% of your buddies",
      myReferrals: "MY REFERRALS",
      noReferralsYet: "You Don't Have Referrals Yet",
      telegramUserIdNotFound: "Telegram user ID not found",
      failedToGenerateInviteLink: "Failed to generate invite link",
      inviteLinkCopied: "Invite link copied to clipboard!",
      unableToCopyInviteLink: "Unable to copy invite link",
      copyLinkManually: "To invite friends, copy the link and share it manually",
      failedToFetchInvitedFriends: "Failed to fetch invited friends",
      fetchError: "An error occurred. Please try again.",
      
      // бустеры
availableBoosts: "Available Boosts",
earnPerTapBoost: "Earn Per Tap Boost",
passiveIncomeBoost: "Passive Income Boost",
increaseEarnPerTap: "Increase your earn per tap",
increasePassiveIncome: "Increase your passive income",
whatDoesThisBoostDo: "What does this boost do?",
notEnoughCoins: "Not enough coins ({balance}/{price})",
energyBoostLimitReached: "Energy Boost Limit Reached",
maximumBoostsReached: "Maximum boosts reached",
activateBoost: "Activate Boost",
  
      // Other
      switchToRussian: "Switch to Russian",
      joinTelegramChannelDescription: "Join our Telegram channel to complete this task and earn your reward!",
      openTelegramChannel: "Open Telegram Channel",
      subscribeToTelegramFirst: "Please subscribe to our Telegram channel first!",
      failedToCheckSubscription: "Failed to check subscription. Please try again.",
      errorOccurred: "An error occurred. Please try again.",
      taskAlreadyCompleted: "This task has already been completed!",
      notEnoughInvitedFriends: "You've invited {invitedFriends} friend(s). Invite {remaining} more to claim this reward!",
      taskCompletedEarnedCoins: "Task completed! You've earned {points} coins!",
      failedToCompleteTask: "Failed to complete task. Please try again.",

      failedToFetchUserToken: "Failed to fetch user token",
      failedToFetchUserData: "Failed to fetch user data",
      failedToLoadUserData: "Failed to load user data. Please try again.",
      energyRecharged: "Energy Recharged! Your clicks have been reset to the maximum!",
      errorResettingClicks: "An error occurred while resetting your clicks.",
      earnPerTapBoosted: "Earn Per Tap boosted! Now earning {amount} per tap.",
      failedToBoostEarnPerTap: "Failed to boost earn per tap. Please try again.",
      passiveIncomeBoosted: "Passive income boosted! Now earning {amount} per hour.",
      failedToBoostPassiveIncome: "Failed to boost passive income. Please try again.",
      price: "Price",
      usage: "Usage",
      hour: "hour",

      // leaderboard
userIdNotFound: "User ID not found",
error: "Error",
failedToFetchLeaderboard: "Failed to fetch leaderboard",
weeklyReferrer: "WEEKLY REFERRER",
winThisWeek: "Win this week",
refs: "refs",
you: "You",

explore: "Explore",
balanceLeaderboard: "Balance Leaderboard",
gemLeaderboard: "Gem Leaderboard",
realRank: "Real Rank",

categories: "Categories",
mansion: "Mansion",
mansionDescription: "Upgrade your luxurious home.",
footballClub: "Football Club",
footballClubDescription: "Manage your own team.",
skinShop: "Skin Shop",
skinShopDescription: "Customize your character",
comingSoon: "Coming Soon",
confirmOrder: "Are you sure you want to order {item}?",
skinShopComingSoon: "This feature is not available yet. Stay tuned!",
orderConfirmation: "You can continue with your order or cancel it.",
cancel: "Cancel",
ok: "OK",
confirm: "Confirm",

errorLoadingUpgrades: "Error loading upgrades",
perHour: "per hour",
topGemCollectors: "Top Gem Collectors",
maxLevelReached: "Max level reached",
upgradeYourFootballClub: "Upgrade Your Football Club",
confirmUpgrade: "Are you sure you want to upgrade {name}?",
topBalances: "Top Balances",
market: "Market",
upgradeDescription: "This upgrade will increase your hourly income by {boost}",



    },
    ru: {
      // Информация о пользователе
      user: "Пользователь",
      level: "Уровень",

      errorLoadingUpgrades: "Ошибка загрузки улучшений",
perHour: "в час",
market: "Магазин",
topGemCollectors: "Топ коллекционеров камней",
topBalances: "Топ богачей",
maxLevelReached: "Достигнут максимальный уровень",
upgradeYourFootballClub: "Улучшите Свой Футбольный Клуб",
confirmUpgrade: "Вы уверены, что хотите улучшить {name}?",
upgradeDescription: "Это улучшение увеличит ваш почасовой доход на {boost}",
explore: "Исследовать",
balanceLeaderboard: "Таблица лидеров по балансу",
gemLeaderboard: "Таблица лидеров по камням",
realRank: "Реальный ранг",

      categories: "Категории",
mansion: "Особняк",
mansionDescription: "Улучшите свой роскошный дом.",
footballClub: "Футбольный Клуб",
footballClubDescription: "Управляйте своей командой.",
skinShop: "Магазин Скинов",
skinShopDescription: "Настройте своего персонажа",
comingSoon: "Скоро",
confirmOrder: "Вы уверены, что хотите заказать {item}?",
skinShopComingSoon: "Эта функция пока недоступна. Следите за обновлениями!",
orderConfirmation: "Вы можете продолжить свой заказ или отменить его.",
cancel: "Отмена",
ok: "OK",
confirm: "Подтвердить",
      
      // Заголовок
      energyFree: "БЕЗЛИМИТНО",
      days: "д",
      hours: "ч",
      minutes: "м",
      seconds: "с",
  
      // Статистика пользователя
      earnPerTap: "Заработок за тап",
      coinsToLevelUp: "До следующего уровня",
      profitPerHour: "Прибыль в час",
  
      // Баланс
      balance: "Баланс",
  
      // Кнопка и действия
      tap: "Тап",
      boost: "Ускорение",
      coins: "{amount} монет",
  
      // Информация об уровнях
      bronze: "Бронзовый",
      silver: "Серебряный",
      gold: "Золотой",
      platinum: "Платиновый",
      diamond: "Бриллиантовый",
      epic: "Эпический",
      legendary: "Легендарный",
      master: "Мастер",
      grandmaster: "Грандмастер",
      lord: "Лорд",
  
      // Навигация
      friends: "Друзья",
      tasks: "Задания",
      dailyRewards: "Ежедневные награды",
      gemMining: "Добыча камней",
      leaderboard: "Таблица лидеров",
      gemExchange: "Обмен камней",
      gem1: "Камень 1",
      gem2: "Камень 2",
      gem3: "Камень 3",
      exchange: "Обмен",


      userIdNotFound: "ID пользователя не найден",
error: "Ошибка",
failedToFetchLeaderboard: "Не удалось загрузить таблицу лидеров",
weeklyReferrer: "ЕЖЕНЕДЕЛЬНЫЙ РЕФЕРЕР",
winThisWeek: "Выиграйте на этой неделе",
refs: "Реф",
you: "Вы",
  
      // Задания
      joinTelegramChannel: "Присоединиться к нашему Telegram каналу",
      telegramChannelDescription: "Подпишитесь на канал Raven в Telegram для получения последних новостей Pixelverse и получите 100,000 монет.",
      followInstagram: "Подписаться на наш Instagram",
      instagramDescription: "Подпишитесь на наш аккаунт в Instagram для эксклюзивного контента и получите 100,000 монет.",
      inviteFriends: "Пригласить 3 друзей",
      inviteFriendsDescription: "Пригласите 3 друзей присоединиться к Raven и получите подарок в 500,000 монет!",
      dailyTasks: "Ежедневные задания",
      yourTasks: "Ваши задания",
      dailyTasksComingSoon: "Ежедневные задания скоро появятся",
      whatNeedToDo: "Что нужно сделать?",
      reward: "Награда",
      start: "Начать",
      goToChannel: "Перейти в канал",
      completed: "Выполнено",
      featured: "Рекомендуемое",
  
      // Загрузка
      loading: "Загрузка...",
  
      // Неподдерживаемая платформа
      unsupportedPlatform: "Кар-кар, Ворон! 📱✨ Чтобы разблокировать магию, откройте наше приложение через мобильное приложение Telegram и позвольте приключению начаться!",

      // бустеры
availableBoosts: "Доступные ускорения",
earnPerTapBoost: "Ускорение заработка за тап",
passiveIncomeBoost: "Ускорение пассивного дохода",
increaseEarnPerTap: "Увеличьте ваш заработок за тап",
increasePassiveIncome: "Увеличьте ваш пассивный доход",
whatDoesThisBoostDo: "Что делает это ускорение?",
notEnoughCoins: "Недостаточно монет ({balance}/{price})",
energyBoostLimitReached: "Достигнут лимит ускорения энергии",
maximumBoostsReached: "Достигнуто максимальное количество ускорений",
activateBoost: "Активировать ускорение",
  
      // AppBar
      main: "Главная",
      gems: "Шахта",
      boosts: "Ускорения",

      // Реферальная программа
      referral: "РЕФЕРАЛЬНАЯ ПРОГРАММА",
      inviteFriendsButton: "ПРИГЛАСИТЬ ДРУЗЕЙ",
      earnedFromReferrals: "Заработано от рефералов",
      score5PercentOfYourBuddies: "Получайте 5% от заработка ваших друзей",
      myReferrals: "МОИ РЕФЕРАЛЫ",
      noReferralsYet: "У вас пока нет рефералов",
      telegramUserIdNotFound: "ID пользователя Telegram не найден",
      failedToGenerateInviteLink: "Не удалось сгенерировать пригласительную ссылку",
      inviteLinkCopied: "Пригласительная ссылка скопирована в буфер обмена!",
      unableToCopyInviteLink: "Не удалось скопировать пригласительную ссылку",
      copyLinkManually: "Чтобы пригласить друзей, скопируйте ссылку и поделитесь ею вручную",
      failedToFetchInvitedFriends: "Не удалось получить список приглашенных друзей",
      fetchError: "Произошла ошибка. Пожалуйста, попробуйте еще раз.",
  
      // Прочее
      switchToEnglish: "Переключить на английский",
      joinTelegramChannelDescription: "Присоединитесь к нашему Telegram-каналу, чтобы выполнить это задание и получить награду!",
      openTelegramChannel: "Открыть Telegram-канал",
      subscribeToTelegramFirst: "Пожалуйста, сначала подпишитесь на наш Telegram-канал!",
      failedToCheckSubscription: "Не удалось проверить подписку. Пожалуйста, попробуйте снова.",
      errorOccurred: "Произошла ошибка. Пожалуйста, попробуйте снова.",
      taskAlreadyCompleted: "Это задание уже выполнено!",
      notEnoughInvitedFriends: "Вы пригласили {invitedFriends} друга(ей). Пригласите еще {remaining}, чтобы получить награду!",
      taskCompletedEarnedCoins: "Задание выполнено! Вы заработали {points} монет!",
      failedToCompleteTask: "Не удалось выполнить задание. Пожалуйста, попробуйте снова.",


      // В объект ru добавьте:
failedToFetchUserToken: "Не удалось получить токен пользователя",
failedToFetchUserData: "Не удалось получить данные пользователя",
failedToLoadUserData: "Не удалось загрузить данные пользователя. Пожалуйста, попробуйте снова.",
energyRecharged: "Энергия восстановлена! Ваши клики сброшены до максимума!",
errorResettingClicks: "Произошла ошибка при сбросе ваших кликов.",
earnPerTapBoosted: "Заработок за тап увеличен! Теперь вы зарабатываете {amount} за тап.",
failedToBoostEarnPerTap: "Не удалось увеличить заработок за тап. Пожалуйста, попробуйте снова.",
passiveIncomeBoosted: "Пассивный доход увеличен! Теперь вы зарабатываете {amount} в час.",
failedToBoostPassiveIncome: "Не удалось увеличить пассивный доход. Пожалуйста, попробуйте снова.",
price: "Цена",
usage: "Использование",
hour: "час",
    }
  };
  
  // Обновленная функция getTranslation
  export const getTranslation = (lang, key) => {
    if (!lang || !key) {
      console.warn(`Missing lang or key in getTranslation: lang=${lang}, key=${key}`);
      return key || '';
    }
  
    if (!translations[lang]) {
      console.warn(`Language "${lang}" not found in translations`);
      return key;
    }
  
    if (!translations[lang][key]) {
      console.warn(`Translation for key "${key}" not found in language "${lang}"`);
      return key;
    }
  
    return translations[lang][key];
  };