import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/bootstrap.css';
import './fonts/css/fontawesome-all.min.css';
import AppBar from './app-bar';
import confetti from 'canvas-confetti';

const Mansion = () => {
  const [theme, setTheme] = useState('theme-dark');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUpgrade, setSelectedUpgrade] = useState(null);
  const [userData, setUserData] = useState(null);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [upgrades, setUpgrades] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
    fetchUpgrades();
  }, []);


  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.BackButton.show();

      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/market');
      });
    }

    return () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.BackButton.hide();
      }
    };
  }, [navigate]);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_user_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({ token: localStorage.getItem('userToken') })
      });
      if (!response.ok) throw new Error('Failed to fetch user data');
      const data = await response.json();
      setUserData(data.user);
    } catch (error) {
      console.error('Error fetching user data:', error);
      showToast('Error', 'error');
    }
  };

  const fetchUpgrades = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_upgrades`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch upgrades');
      const data = await response.json();
      console.log('Received upgrades data:', data);
      // Убедитесь, что baseprice - число
      const upgrades = data.upgrades.map(upgrade => ({
        ...upgrade,
        baseprice: Number(upgrade.baseprice)
      }));
      setUpgrades(upgrades);
    } catch (error) {
      console.error('Error fetching upgrades:', error);
      showToast('Error loading upgrades', 'error');
    }
  };

  const handleHapticFeedback = (intensity = 'medium') => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred(intensity);
    }
  };

  const openConfirmation = (upgrade) => {
    handleHapticFeedback();
    setSelectedUpgrade(upgrade);
    setShowConfirmation(true);
  };

  const closeConfirmation = () => {
    handleHapticFeedback();
    setShowConfirmation(false);
    setSelectedUpgrade(null);
  };

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
    setTimeout(() => setToast({ show: false, message: '', type: '' }), 4000);
  };

  const launchConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const buyUpgrade = async () => {
    if (!selectedUpgrade || !userData) return;

    const level = userData[`${selectedUpgrade.id}_level`] || 0;
    if (level >= 5) {
      showToast('Max level', 'error');
      closeConfirmation();
      return;
    }

    const price = Math.round(selectedUpgrade.baseprice * Math.pow(1.5, level));
    if (userData.balance < price) {
      showToast('Low balance', 'error');
      closeConfirmation();
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/buy_upgrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
          token: localStorage.getItem('userToken'),
          upgradeId: selectedUpgrade.id,
          price: price
        })
      });

      if (!response.ok) throw new Error('Failed to buy upgrade');
      const data = await response.json();
      setUserData(data.user);
      showToast('Success!');
      launchConfetti();
      handleHapticFeedback('heavy');
    } catch (error) {
      console.error('Error buying upgrade:', error);
      showToast('Fail', 'error');
    }

    closeConfirmation();
  };

  const renderStars = (level) => {
    return (
      <p className="starcenter mb-3">
        {[...Array(5)].map((_, index) => (
          <i key={index} className={`fa fa-star ${index < level ? 'color-yellow-dark' : 'color-yellow1-dark'}`}></i>
        ))}
      </p>
    );
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  };

  const renderUpgradeCard = (upgrade) => {
    console.log('Rendering upgrade:', upgrade);
    const level = userData ? userData[`${upgrade.id}_level`] || 0 : 0;
    console.log('Level for', upgrade.id, ':', level);
    console.log('Base price:', upgrade.baseprice);
    const price = Math.round(upgrade.baseprice * Math.pow(1.5, level));
    console.log('Calculated price:', price);
    
    const isMaxLevel = level >= 5;

    return (
      <div className="col-6 pe-0" key={upgrade.id}>
        <div 
          className={`card card-style mx-0 mb-2 ${isMaxLevel ? 'opacity-70' : ''}`} 
          style={{backgroundImage: `url("${upgrade.image}")`, height: '170px'}} 
          onClick={() => !isMaxLevel && openConfirmation(upgrade)}
        >
          <div className="card-bottom p-3">
            <h4 className="color-white">{upgrade.name}</h4>
            {!isMaxLevel && (
              <>
                <p className="color-white opacity-80 line-height-s font-12">
                {formatNumber(upgrade.boost)} per hour
                </p>
                <p className="color-white opacity-80 line-height-s font-12">
                  Price: {formatNumber(price)}
                  <img src="/images/icons/coin.png" alt="Coin" style={{width: '15px', height: '15px', verticalAlign: 'middle', marginLeft: '2px'}} />
                </p>
              </>
            )}
            {isMaxLevel && (
              <p className="color-white opacity-80 line-height-s font-12">
                Max level reached
              </p>
            )}
          </div>
          {renderStars(level)}
          <div className="card-overlay bg-gradient opacity-30"></div>
          <div className="card-overlay bg-gradient"></div>
        </div>
      </div>
    );
  };

  return (
    <div className={theme}>
      <div id="page">
      <div className="divider mt-4"></div>
        
        <div className="page-c0ntent">
          <div className="card card-style" style={{backgroundImage: 'url("/images1/mansion/main.jpg")', minHeight: '300px'}}>
            <div className="card-bottom px-3 py-3">
              <h1 className="color-white">Upgrade Your Mansion</h1>
            </div>
            <div className="card-overlay bg-gradient opacity-30"></div>
            <div className="card-overlay bg-gradient"></div>
          </div>

          <div className="c0ntent">
            <div className="row mb-0">
              {upgrades.map(renderUpgradeCard)}
              <div className="divider mt-4"></div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <div 
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            background: '#21252a',
            padding: '20px',
            boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
            display: 'block',
            height: '320px'
          }}
        >
          <h1 className="text-center mt-4"><i className="fa fa-3x fa-info-circle scale-box color-blue-dark shadow-xl rounded-circle"></i></h1>
          <h3 className="text-center mt-3 font-700">Are you sure you want to upgrade {selectedUpgrade?.name}?</h3>
          <p className="boxed-text-xl opacity-70"> 
            This upgrade will increase your hourly income by {formatNumber(selectedUpgrade?.boost)}
            <img src="/images/icons/coin.png" alt="Coin" style={{width: '15px', height: '15px', verticalAlign: 'middle', marginLeft: '2px'}} />.
          </p>
          <div className="row mb-0 me-3 ms-3">
            <div className="col-6">
              <button className="btn btn-full btn-m color-red-dark border-red-dark font-600 rounded-s" onClick={closeConfirmation}>Cancel</button>
            </div>
            <div className="col-6">
              <button className="btn btn-full btn-m color-green-dark border-green-dark font-600 rounded-s" onClick={buyUpgrade}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      <div id="toast-1" className={`toast toast-tiny toast-top ${toast.type === 'error' ? 'bg-red-dark' : 'bg-green-dark'} fade ${toast.show ? 'show' : 'hide'}`}>
        <i className={`fa ${toast.type === 'error' ? 'fa-times' : 'fa-check'} me-3`}></i>
        {toast.message}
      </div>

      <AppBar language="en" />
    </div>
  );
};

export default Mansion;